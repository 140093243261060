import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService
} from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { User } from '../../../pages/customers/customers.type';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  menuClickSubscription: Subscription;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: User;

  themes = [
    { value: 'default', name: 'Light' },
    { value: 'dark', name: 'Dark' },
    { value: 'cosmic', name: 'Cosmic' },
    { value: 'corporate', name: 'Corporate' }
  ];

  currentTheme = 'default';

  userMenu: NbMenuItem[] = [
    { title: 'Have a Break', data: { action: () => window.open('https://app.leavedates.com', '_blank') } },
    { title: 'Log out', data: { action: () => this.logout() } }
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.user = localStorage.user ? JSON.parse(localStorage.user) : null;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

    this.menuClickSubscription = this.menuService
      .onItemClick()
      .subscribe((event) => {
        if(event.tag === 'user-menu') {
          event.item.data.action();
        }
      });
  }

  ngAfterViewInit() {
    this.toggleSidebar()
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.menuClickSubscription.unsubscribe();
  }

  logout() {
    localStorage.removeItem('auth_app_token');
    localStorage.removeItem('user');
    this.router.navigateByUrl('/auth/login');
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
